<template>
  <div class="orders">
    <div class="card">
      <h2 class="card-header bg-primary text-white text-uppercase">
        Orders
      </h2>
      <div class="card-body">
        <div class="my-3 float-right">
          <form class="d-flex justify-content-center">
          <input
            type="search"
            placeholder="Rechercher"
            id="rechercheGlobal"
            aria-label="Search"
            class="form-control"
            v-model.trim="filter"
          />
          <button
            class="btn btn-primary btn-sm my-0 p"
            id="chercherBtn"
            type="submit"
          >
            <i class="fas fa-search"></i>
          </button>
        </form>
        </div>
        <div class="clearfix"></div>
        <div class="text-center mx-auto" v-if="loading">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="mx-auto" v-else-if="!orders.length">
          No data available
        </div>
        <div v-else class="row">
          <div
            class="col-md-4"
            v-for="(order, index) in ordersFilter.filter(order => order.service_type.service_name.toUpperCase().includes(filter.toUpperCase()))"
            :key="order._id"
          >
            <div class="card mb-4">
              <div class="card-header bg-primary text-white text-uppercase">
                <h5>
                  {{ order.service_type.service_name }}
                </h5>
              </div>
              <div class="card-body">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item p-0">
                    <div class="accordion mb-4" id="accordionCar">
                      <div class="card">
                        <div class="text-center bg-primary">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link text-white"
                              type="button"
                              data-toggle="collapse"
                              :data-target="'#cord' + order._id"
                              aria-expanded="true"
                              aria-controls="cord0"
                            >
                              Car details
                            </button>
                          </h2>
                        </div>
                        <!-- show -->
                        <div
                          :id="'cord' + order._id"
                          class="collapse p-3"
                          aria-labelledby="headingOne"
                          data-parent="#accordionCar"
                        >
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                              <span class="font-weight-bold">Make</span> :
                              <span>{{ order["car"]["make"] }}</span>
                            </li>
                            <li class="list-group-item">
                              <span class="font-weight-bold">Trim</span> :
                              <span>{{ order["car"]["trim"] }}</span>
                            </li>
                            <li class="list-group-item">
                              <span class="font-weight-bold">Model</span> :
                              <span>{{ order["car"]["model"] }}</span>
                            </li>
                            <li class="list-group-item">
                              <span class="font-weight-bold">Description</span>
                              :
                              <p>{{ order["details"] }}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <span class="font-weight-bold">Posted by</span> :
                    <span
                      class="text-primary"
                      style="cursor: pointer"
                      data-toggle="modal"
                      data-target="#userInfo"
                      @click="setUserInfo(order['car']['user_id'])"
                      >{{ order["car"]["user_id"]["firstname"] }}
                      {{ order["car"]["user_id"]["lastname"] }}</span
                    >
                  </li>

                  <li class="list-group-item">
                    <span class="font-weight-bold">Price</span> :
                    <span>{{
                      order["price"] ? formatPrice(order["price"]) : "Not Set"
                    }}</span>
                  </li>
                  <li class="list-group-item">
                    <span class="font-weight-bold">status</span> :

                    <span
                      class="badge badge-pill"
                      :class="getStatusPill(order['status'])"
                      >{{ order.status }}</span
                    >
                  </li>
                  <li class="list-group-item" v-if="order['taken_by']">
                    <span class="font-weight-bold text-blue">Taken by</span> :
                    <span
                      class="text-primary"
                      style="cursor: pointer"
                      data-toggle="modal"
                      data-target="#userInfo"
                      @click="setUserInfo(order['taken_by'])"
                      >{{ order["taken_by"]["firstname"] }}
                      {{ order["taken_by"]["lastname"] }}</span
                    >
                  </li>
                </ul>
                <!-- Button trigger modal price -->
                <div class="btn-group btn-group-sm">
                  <button
                    v-if="
                      order.status == 'rejected' || order.status == 'pending'
                    "
                    type="button"
                    class="btn btn-success btn-sm waves-effect"
                    @click="
                      validate(order, 'validated');
                      setOrderId(order['_id'], index);
                    "
                  >
                    Validate
                  </button>

                  <button
                    v-if="
                      order.status == 'validated' || order.status == 'pending'
                    "
                    type="button"
                    class="btn btn-danger btn-sm waves-effect"
                    @click="
                      validate(order, 'rejected');
                      setOrderId(order['_id'], index);
                    "
                  >
                    Reject
                  </button>

                  <button
                    v-if="
                      order.status == 'pending' || order.status == 'rejected'
                    "
                    type="button"
                    class="btn btn-primary btn-sm waves-effect"
                    data-toggle="modal"
                    data-target="#priceModal"
                    @click="setOrderId(order['_id'], index)"
                  >
                    Price
                  </button>
                </div>
              </div>
              <div class="card-footer bg-white text-muted">
                Created :
                <span class="badge badge-pill badge-success my-2">
                  {{ getDate(order["createdAt"]) }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- pagination -->
        <nav aria-label="navigation" class="text-center">
          <ul class="pagination">
            <li class="page-item" :class="{ disabled: initialPage < 2 }">
              <a class="page-link" href="#" tabindex="-1">Previous</a>
            </li>
            <li class="page-item" :class="{ active: initialPage == 1 }">
              <a class="page-link" href="#">1</a>
            </li>
            <li
              class="page-item"
              :class="{ active: initialPage == 2, disabled: total < 10 }"
            >
              <a class="page-link" href="#"
                >2 <span class="sr-only">(current)</span></a
              >
            </li>
            <li
              class="page-item"
              :class="{ active: initialPage == 3, disabled: total < 20 }"
            >
              <a class="page-link" href="#">3</a>
            </li>
            <li class="page-item" :class="{ disabled: total < 10 }">
              <a class="page-link" href="#">Next</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <!-- Modal Price-->
    <div
      class="modal fade"
      id="priceModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modelTitleId"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Set Price</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Price -->
            <div class="md-form">
              <input
                type="text"
                id="price"
                class="form-control"
                v-model="price"
              />
              <label for="price">Price</label>
            </div>
            <!-- Save button -->
            <button
              class="
                btn btn-outline-info btn-rounded btn-block
                z-depth-0
                my-4
                waves-effect
              "
              type="button"
              @click="setPrice()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--Modal: user information-->
    <div
      class="modal fade"
      id="userInfo"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog cascading-modal modal-avatar modal-sm"
        role="document"
      >
        <!--Content-->
        <div class="modal-content">
          <!--Header-->
          <div class="modal-header">
            <div
              class="rounded-circle bg-white w-100 h-100"
              v-if="!userInfo.profile"
            >
              Profile
            </div>

            <img
              :src="userInfo.profile"
              alt="avatar"
              class="rounded-circle img-responsive"
              style="height: 130px;"
            />
          </div>
          <!--Body-->
          <div class="modal-body text-center mb-1">
            <h5 class="mt-1 mb-2 text-primary">{{ fullName }}</h5>

            <div>
              <span class="font-weight-bold">Email : </span
              ><span>{{ userInfo.email }}</span>
            </div>
            <div>
              <span class="font-weight-bold">SSN : </span
              ><span>{{ userInfo.id_number }}</span>
            </div>
            <div>
              <span class="font-weight-bold">Address : </span
              ><span>{{ userInfo.address }}</span>
            </div>

            <div>
              <span class="font-weight-bold">Verified : </span
              ><span
                v-if="userInfo.verified"
                class="badge badge-success badge-pill"
                >Verified
              </span>
              <span v-else class="badge badge-danger badge-pill"
                >Not Verified</span
              >
            </div>
          </div>
        </div>
        <!--/.Content-->
      </div>
    </div>
    <!--Modal: user information-->
  </div>
</template>

<script>
import swal from "sweetalert";
const axios = require("axios");
// import $ from "jquery";

export default {
  data() {
    return {
      orders: [],
      ordersFilter: [],
      filter : "",
      userInfo: {
        firstname: "",
        lastname: "",
        profile: "",
        email: "",
        id_number: "",
        address: "",
      },
      price: "",
      orderId: "",
      orderIndex: 0,
      initialPage: 1,
      total: null,
      loading: false,
    };
  },
  sockets: {
    connect: function() {
      console.log("socket connected");
    },
    receive_job_client: function(data) {
      console.log(data["order"]);
      this.orders = this.orders.map((order) => {
        if (order._id == data["order"]) {
          order.taken_by = data["taken_by"];
          return order;
        } else return order;
      });
      console.log("**************>>", this.orders);
    },
    released_job_client: function(data) {
      console.log(data["order"]);
      this.orders = this.orders.map((order) => {
        if (order._id == data["order"]) {
          order.taken_by = null;
          return order;
        } else return order;
      });
      console.log(this.orders);
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(`/api/orders?page=${this.initialPage}&status=all`)
        .then((response) => {
          this.loading = false;
          this.total = response.data[0].total;
          this.orders = response.data[1].data;
          this.ordersFilter = this.orders;
          console.log("orders", this.orders);
          this.loading = false;
        })
        .catch(function(error) {
          swal("Oops", error.toString(), "error");
        });
    },
    getDate(timestamp) {
      var date = new Date(timestamp);
      return (
        date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    },
    setUserInfo(userData) {
      console.log(userData.firstname);
      this.userInfo.firstname = userData.firstname;
      this.userInfo.lastname = userData.lastname;
      var img = new Image();
      img.onload = () => {
        this.userInfo.profile = userData.profile;
      };
      img.src = userData.profile;
      this.userInfo.profile = "../assets/images/loader.gif";
      this.userInfo.email = userData.email;
      this.userInfo.id_number = userData.id_number;
      this.userInfo.address = userData.address;
      this.userInfo.verified = userData.verified;
    },
    setOrderId(id, index) {
      this.orderId = id;
      this.orderIndex = index;
    },
    setPrice() {
      axios
        .patch("/api/orders/" + this.orderId, [
          {
            propName: "price",
            value: this.price,
          },
        ])
        .then(() => {
          this.orders[this.orderIndex].price = this.price;
          swal("Success", "Price settled successfully", "success");
          // $("#priceModal").modal("hide");
        })
        .catch((err) => {
          swal("Oops", err.toString(), "error");
        });
    },
    formatPrice(price) {
      return price / 100 + " $";
    },
    validate(order, status) {
      swal(`Do you want to set ${status} the job to the mechanics.`).then(
        (value) => {
          if (value && ((order["price"] != "" && status == "validated") || (status == "rejected")) ) {
            axios
              .patch("/api/orders/" + this.orderId, [
                {
                  propName: "status",
                  value: status,
                },
              ])
              .then(() => {
                this.orders[this.orderIndex].status = status;
                if (status == "validated") {
                  this.$socket.emit("send_job", order);
                } else {
                  this.$socket.emit("delete_job", {
                    id: order._id,
                    tokens: [order["car"]["user_id"]["token"]],
                    fullName: "Carixin (admin)",
                  });
                  console.log("delete_job socket emit");
                }
                swal("Success", "status changed successfully", "success");
              })
              .catch((err) => {
                swal("Oops", err.toString(), "error");
              });
          } else {
            swal(
              "Price missing",
              "You should set the price of the service before",
              "error"
            );
          }
        }
      );
    },
    getStatusPill(status) {
      switch (status) {
        case "pending":
          return "badge-warning";
        case "rejected":
          return "badge-danger";
        case "validated":
          return "badge-success";
        default:
          return "badge-success";
      }
    },
    filterOrder(){
      console.log(this.filter);
    }
  },
  computed: {
    fullName() {
      if (this.userInfo.firstname && this.userInfo.lastname)
        return this.userInfo.firstname + " " + this.userInfo.lastname;
      return "";
    },
  },
  mounted() {
    this.fetchData();
    this.orders.map((order) => {
      console.log(order);
    });
  },
};
</script>
